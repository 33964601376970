:root {
  font-size: 60%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Sul Sans,sans-serif; */
}

html,
body,
#root {
  height: 100vh;
}

body,
input,
button,
textarea {
  font: 500 1.6rem SegoeUI;
}

/* ScrollBar Global Pages*/

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #2c303a;
  border-radius: 5px;
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}